<template>
  <div class="mx-4 mt-8">
    <div v-if="loading">
      Please wait...
    </div>
    <div v-else>
      <div class="pb-3 mb-6 border-b-2 border-black">
        <p class="text-xl">Voting history</p>
      </div>
      <div v-for="(vote,index) in votes" :key="index" class="mt-4 pb-2 border-b-2 border-black">
        <div>{{ vote.weekDate.start_date }} - {{ vote.weekDate.end_date }}</div>
        <div>Voted for {{ vote.artist.member_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../api/http";
import { mapGetters } from "vuex";

export default {
  name: "VotingHistory",
  data() {
    return {
      votes: [],
      loading: true,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({user: 'User/user'})
  },
  methods: {
    init() {
      API.get("voting-history").then((response) => {
        this.loading = false;
        this.votes = response.votes
      });
    },
  },
};
</script>
