<template>
    <div>
        <Loading v-if="processing" />
        <div class="text-center mt-8 md:m-3 m-3">
            <p class="lg:text-sm md:text-2xl mt-2 mb-5">Add youtube URL:</p>
            <input type="text" name="mediaUrl" class="
          text-xl
          border-8
          rounded-2xl
          border-black
          md:py-4
          py-2
          pl-2
          w-full
          focus:
        " v-model="mediaData.url" />
            <div class="mt-4 mb-16">
                <button :disabled="processing" class="text-white px-5 py-1 rapbg rounded-lg font-black"
                    @click="uploadMedia">
                    Add
                </button>
                <div class="mt-6" v-if="user.artist_songs_count == 0">
                    Too add more youtube videos press the more (3 Dots) button above
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Loading from "../../Shared/Loading.vue";

export default {
    name: "AddMedia",
    components: { Loading },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            mediaData: {
                url: null,
            },
            processing: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters["User/user"];
        },
    },
    methods: {
        uploadMedia() {
            this.processing = true;
            if (this.mediaData.url) {
                API.post("upload-media", this.mediaData)
                    .then((response) => {
                        this.processing = false;
                        this.mediaData.url = "";
                        this.toast.success(response.message);
                        // this.$router.push({name:'ProfileWithSlug', params: { slug: this.user.slug }});
                        this.$emit("mediaAdded");
                        let userNew = { ...this.user };
                        userNew.artist_songs_count++;
                        API.setUser(userNew);
                    })
                    .catch(() => {
                        this.processing = false;
                    });
            }
            else {
                this.processing = false;
            }
        },
    },
};
</script>